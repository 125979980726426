<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('documents.myDocuments') }}</v-card-title>
		<v-card-subtitle>{{ $t('search.showAll') }}</v-card-subtitle>
		<v-card-text>
			<v-list two-line>
				<SettingsDocumentsAllItem v-for="document in sortedDocuments" :key="`document${document.id}`" :document="document" />
			</v-list>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsDocumentsAll',
	data() {
		return {
			loading: true,
		}
	},
	components: {
		SettingsDocumentsAllItem: () => import('@/components/settings/SettingsDocumentsAllItem.vue'),
	},
	computed: {
		sortedDocuments() {
			return this.documents ? Array.prototype.slice.call(this.documents).sort((a, b) => (a.principal === b.principal ? 0 : a.principal ? -1 : 1)) : []
		},
		...mapGetters({
			documents: 'documents/documents',
		}),
	},
	created() {
		this.fetchDocuments().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('documents', ['fetchDocuments']),
	},
}
</script>
